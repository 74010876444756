<template></template>

<script>
import Modal from '@/components/global/Modal.vue'
import { EventBus } from '@/event-bus.js'

export default {
  data() {
    return {
      show: false,
      processing: false,
    }
  },

  components: {
    Modal,
  },

  mounted() {
    EventBus.$on(
      'close-modal',
      function () {
        this.show = false
      }.bind(this)
    )

    EventBus.$off('hide-login')
    EventBus.$on(
      'hide-login',
      function () {
        this.show = false
      }.bind(this)
    )

    EventBus.$off('prompt-login')
    EventBus.$on(
      'prompt-login',
      function () {
        this.show = true
        this.signInWith()
      }.bind(this)
    )
  },

  methods: {
    async signInWith() {
      this.$auth.loginWith('auth0', { params: { prompt: 'login' } })
      this.show = false
    },
  },
}
</script>