<template>
  <div class="w-full bg-footerGray pt-15" style="padding-bottom: calc(1.5rem + constant(safe-area-inset-bottom)); padding-bottom: calc(1.5rem + env(safe-area-inset-bottom))">
    <div class="flex flex-col w-full mx-auto max-w-screen-xl">
      <div class="text-center w-full">
        <span class="text-secondaryBlack text-xl font-sans-lato font-bold">Help Our Community Grow</span>
      </div>

      <div class="border-b border-footerBorderGray mb-18.75">
        <div class="flex items-center w-full mt-10 mb-8 mx-auto max-w-screen-md">
          <div class="w-1/3 text-center flex flex-col">
            <span class="text-secondaryBlack font-sans-lato font-bold text-2xl md:text-4xl">{{ stats.bars }}</span>
            <span class="text-secondaryBlack mt-3 font-sans-lato uppercase text-sm md:text-base">Bars & Clubs</span>
          </div>
          <div class="w-1/3 text-center flex flex-col">
            <span class="text-secondaryBlack font-sans-lato font-bold text-2xl md:text-4xl">{{ stats.stores }}</span>
            <span class="text-secondaryBlack mt-3 font-sans-lato uppercase text-sm md:text-base">Stores</span>
          </div>
          <div class="w-1/3 text-center flex flex-col">
            <span class="text-secondaryBlack font-sans-lato font-bold text-2xl md:text-4xl">{{ stats.users }}</span>
            <span class="text-secondaryBlack mt-3 font-sans-lato uppercase text-sm md:text-base">Headbangers</span>
          </div>
        </div>
      </div>

      <div class="border-b border-footerBorderGray mb-18.75">
        <div class="flex items-start w-full mt-10 mb-8 mx-auto max-w-screen-md">
          <div class="w-1/3 text-center flex flex-col ml-5">
            <span class="text-secondaryBlack text-xl font-sans-lato font-bold">About</span>
            <span class="text-secondaryBlack mt-3 font-sans-lato text-sm md:text-base">
              This a world wide travel guide of rock/metal bars, clubs and shops.<br />Here your will be able to find the closest venue where you can bang your head.</span
            >
          </div>
          <div class="w-1/3 text-center flex flex-col mr-5 ml-5">
            <span class="text-secondaryBlack text-xl font-sans-lato font-bold">Add a venue</span>
            <span class="text-secondaryBlack mt-3 font-sans-lato text-sm md:text-base"
              >If you are missing a bar, club or shop then please use our add venue function. It's completely free to list to be listed here.</span
            >
          </div>
          <div class="w-1/3 text-center flex flex-col mr-5">
            <span class="text-secondaryBlack text-xl font-sans-lato font-bold">Buy me a beer</span>
            <span class="text-secondaryBlack mt-3 font-sans-lato text-sm md:text-base">
              If you like this site and find it useful then don't hesitate to buy me a beer.
              <a href="https://buymeacoffee.com/HeadbangersTG" target="_blank" rel="noopener">
                <img src="~/assets/img/logos/bmc-button.svg" loading="lazy" alt="But me a coffee" class="md:p-5 pt-5 pb-0" />
              </a>
            </span>
          </div>
        </div>
      </div>

      <div class="flex flex-col mx-auto items-center text-center max-w-2xl mb-18.75">
        <div>
          <img src="~/assets/img/logos/header-logo.svg" loading="lazy" alt="Logo" />
        </div>
        <div class="font-sans-lato text-base text-placeholderGray mt-2">
          &copy; 2024 - Headbangers Travel Guide<br />
          <nuxt-link :to="'/privacy'">Privacy Policy</nuxt-link> |
          <nuxt-link :to="'/contact'">Contact</nuxt-link>
        </div>
        <div class="mt-2 flex items-center">
          <a href="https://www.instagram.com/headbangerstravelguide/" rel="noopener" target="_blank" class="mr-1">
            <img src="~/assets/img/logos/instagram.svg" class="h-8" alt="Visit us on Instagram" />
          </a>
          <a href="https://twitter.com/HeadbangersTG" rel="noopener" target="_blank" class="ml-1 mr-1">
            <img src="~/assets/img/logos/twitter.svg" class="h-8" alt="Visit us on Twitter" />
          </a>
          <a href="https://www.facebook.com/HeadbangersTravelGuide" rel="noopener" target="_blank" class="ml-1">
            <img src="~/assets/img/logos/facebook.svg" class="h-8" alt="Visit us on Facebook" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      stats: (state) => state.stats,
    }),
  },
}
</script>