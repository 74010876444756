<template>
  <header
    style="padding-top: constant(safe-area-inset-top); padding-top: env(safe-area-inset-top); height: calc(4.375rem + constant(safe-area-inset-top)); height: calc(4.375rem + env(safe-area-inset-top))"
    class="bg-white border-b border-grayBorder w-full flex items-center z-100 md:h-18.75"
  >
    <div class="flex items-center mx-auto w-full max-w-screen-xxl">
      <div class="hidden w-1/4 px-4 md:block lg:w-2/5">
        <nuxt-link :to="'/'">
          <img src="~/assets/img/logos/header-logo.svg" loading="lazy" alt="Logo" />
        </nuxt-link>
      </div>
      <transition name="fade-in">
        <div class="absolute md:hidden" v-show="showNav">
          <a v-on:click="$router.go(-1)" class="cursor-pointer flex items-center justify-center w-8 h-8 ml-3">
            <img src="~/assets/img/ui/back.svg" alt="Back" class="h-5 pointer-events-none" />
          </a>
        </div>
      </transition>
      <div class="mx-auto w-3/4 text-right md:pr-8 md:mx-0 lg:pr-0 lg:w-3/5 lg:text-center">
        <search />
      </div>
      <div class="hidden w-2/5 px-4 lg:block">
        <div class="flex items-center justify-end w-full" v-if="!$auth.loggedIn">
          <a v-on:click="promptLogin()" class="cursor-pointer bg-white font-sans-lato my-3 mx-8 text-secondaryBlack uppercase text-base font-bold">Sign In</a>
        </div>
        <div class="flex items-center justify-end w-full relative" v-if="$auth.loggedIn">
          <a
            v-on:click="showDropdown = true"
            id="htg-dropdown-link"
            class="cursor-pointer flex items-center border gray-grayBorder rounded-full py-1 pr-2 pl-4 text-base font-sans-lato font-bold text-secondaryBlack"
          >
            {{ $auth.user.name }}
            <GravatarImg :email="$auth.user.email" classes="w-8 h-8 ml-2 rounded-full" alt="Profile picture" />
          </a>

          <div v-show="showDropdown" class="top-50 rounded-lg absolute bg-white flex flex-col border border-grayBorder shadow z-modal" id="htg-header-dropdown">
            <div class="p-3 border-b border-grayBorder flex flex-col font-sans-lato font-bold text-base">
              <span>Signed in as</span>
              <span>{{ $auth.user.email }}</span>
            </div>
            <nuxt-link class="p-3 cursor-pointer hover:bg-autocompleteHoverGray border-b border-grayBorder font-sans-lato text-base" :to="'/account'"> My Account </nuxt-link>
            <a v-on:click="logout()" class="p-3 cursor-pointer hover:bg-autocompleteHoverGray font-sans-lato text-base" :to="'/logout'"> Logout </a>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { EventBus } from '@/event-bus.js'
import Search from '@/components/global/Header/Search.vue'
import GravatarImg from '@/components/global/GravatarImg.vue'

export default {
  data() {
    return {
      showDropdown: false,
    }
  },

  components: {
    Search,
    GravatarImg,
  },

  mounted() {
    this.bindDropdown()

    EventBus.$on(
      'htg-login',
      function () {
        this.bindDropdown()
      }.bind(this)
    )
  },

  watch: {
    $route(to, from) {
      this.showDropdown = false
    },
  },

  computed: {
    showNav() {
      return this.$route.name != 'index'
    },
  },

  methods: {
    async logout() {
      await this.$auth.logout()
    },

    bindDropdown() {
      let headerDropdown = document.querySelector('#htg-header-dropdown')
      let dropdownLink = document.querySelector('#htg-dropdown-link')

      document.body.addEventListener(
        'click',
        function (event) {
          if (this.showDropdown && !headerDropdown.contains(event.target) && !dropdownLink.contains(event.target)) {
            this.showDropdown = false
          }
        }.bind(this)
      )
    },

    promptLogin() {
      this.$store.commit('pendingActions/setPreAuthAction', {})
      EventBus.$emit('prompt-login')
    },

    promptRegister() {
      EventBus.$emit('prompt-register')
    },
  },
}
</script>
