export const state = () => ({
    freeText: '',
    categories: [],
})

export const mutations = {
    setFreeText(state, value) {
        state.freeText = value;
    },

    setCategories(state, value) {
        state.categories = value;
    },
}