<template>
  <transition name="slide-in-bottom">
    <div id="htg-select-categories-container-mobile" class="fixed top-0 right-0 bottom-0 left-0 bg-white z-modal md:hidden" v-show="showCategoriesSelect">
      <div
        style="
          padding-top: constant(safe-area-inset-top);
          padding-top: env(safe-area-inset-top);
          height: calc(4.375rem + constant(safe-area-inset-top));
          height: calc(4.375rem + env(safe-area-inset-top));
        "
        class="h-17.5 border-b border-grayBorder flex items-center justify-between px-4"
      >
        <span v-on:click="closeMobile()">
          <img src="~/assets/img/ui/close-mobile.svg" class="cursor-pointer pointer-events-none" alt="Close" />
        </span>

        <span class="font-sans-lato bold text-base text-secondaryBlack font-bold">Categories X</span>

        <span class="underline font-sans-lato bold text-base text-brown font-bold cursor-pointer" v-on:click="clearCategories()">Clear</span>
      </div>

      <div class="p-5 flex flex-col">
        <div class="w-full mt-2" v-for="category in categories" v-bind:key="'select-category-' + category.id">
          <label class="htg-checkbox-container flex items-center h-7.5"
            ><span class="font-sans-lato text-base">{{ category.name }}</span>
            <input type="checkbox" v-bind:value="category.id" v-on:change="syncCategories()" v-model="selectedCategories" />
            <span class="htg-checkmark"></span>
          </label>
        </div>

        <a v-on:click="applySearch()" class="cursor-pointer rounded bg-brown font-sans-montserrat py-2 px-8 text-white text-center text-base uppercase mt-8">Save</a>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapState } from 'vuex'
import { EventBus } from '@/event-bus.js'

export default {
  data() {
    return {
      showCategoriesSelect: false,
      selectedCategories: [],
    }
  },

  mounted() {
    this.syncLocal()

    EventBus.$on(
      'htg-clear-local-filters',
      function () {
        this.selectedCategories = []
        this.syncCategories()
      }.bind(this)
    )

    EventBus.$off('htg-hide-categories-modal')
    EventBus.$on(
      'htg-hide-categories-modal',
      function () {
        this.showCategoriesSelect = false
      }.bind(this)
    )

    EventBus.$off('htg-show-categories-modal')
    EventBus.$on(
      'htg-show-categories-modal',
      function () {
        this.syncLocal()
        this.showCategoriesSelect = true
      }.bind(this)
    )
  },

  computed: {
    ...mapState({
      categories: (state) => state.categories,
    }),

    ...mapState('filters', {
      persistantCategories: (state) => state.categories,
    }),
  },

  methods: {
    closeMobile() {
      this.showCategoriesSelect = false
    },

    syncLocal() {
      this.selectedCategories = this.persistantCategories
    },

    syncCategories() {
      this.$store.commit('filters/setCategories', this.selectedCategories)
      EventBus.$emit('htg-sync-categories-filter')
    },

    clearCategories() {
      this.selectedCategories = []
      this.syncCategories()
      this.applySearch()
    },

    applySearch() {
      EventBus.$emit('htg-submit-search')
      this.showCategoriesSelect = false
    },
  },
}
</script>