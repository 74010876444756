<template>
  <div class="flex rounded items-center md:inline-flex md:h-12.5 md:shadow-headerSearch">
    <nuxt-link :to="'/'">
      <div
        class="hidden justify-center items-center px-4 h-12.5 text-placeholderGray hover:text-secondaryBlack text-sm font-sans-lato border-r border-t border-b border-grayBorder cursor-pointer md:flex"
      >
        Home
      </div>
    </nuxt-link>
    <nuxt-link :to="'/map'">
      <div
        class="hidden justify-center items-center px-4 h-12.5 text-placeholderGray hover:text-secondaryBlack text-sm font-sans-lato border-r border-t border-b border-grayBorder cursor-pointer md:flex"
      >
        Map
      </div>
    </nuxt-link>
    <nuxt-link :to="'/venue/new'">
      <div
        class="hidden justify-center items-center px-4 h-12.5 text-placeholderGray hover:text-secondaryBlack text-sm font-sans-lato border-r border-t border-b border-grayBorder cursor-pointer md:flex"
      >
        Add Venue
      </div>
    </nuxt-link>

    <div v-on:click="showSearch()" class="hidden cursor-pointer justify-center items-center rounded-tr rounded-br px-4 h-12.5 border-r border-t border-b border-grayBorder cursor-pointer md:flex">
      <img src="~/assets/img/icons/search.svg" alt="Search" class="pointer-events-none" />
    </div>
    <div v-on:click="showSearch()" class="flex items-center w-full mx-auto cursor-pointer rounded-full px-4 py-3 shadow-headerSearch border border-grayBorder md:hidden">
      <img src="~/assets/img/icons/search.svg" alt="Search" class="pointer-events-none" />
      <span v-show="freeText == ''" class="ml-4 text-placeholderGray font-sans-lato text-sm">Search for venue</span>
      <span v-show="freeText != ''" class="ml-4 max-w-170 truncate text-secondaryBlack font-sans-lato text-sm">{{ freeText }}</span>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/event-bus.js'
import { mapState } from 'vuex'

export default {
  methods: {
    showSearch() {
      EventBus.$emit('show-search')
    },
  },

  computed: {
    ...mapState('filters', {
      freeText: (state) => state.freeText,
    }),
  },
}
</script>