<style scoped>
main {
  min-height: 100vh;
  display: grid;
  grid-template: 'main';
  flex: 1;
  position: relative;
  overflow: hidden;
  background-color: white;
}

main > * {
  grid-area: main; /* Transition: make sections overlap on same cell */
  flex: 1 1 auto;
  background-color: white;
  position: relative;
}

main > :first-child {
  z-index: 1; /* Prevent flickering on first frame when transition classes not added yet */
}
</style>
<template>
  <div
    id="app-layout"
    class="flex flex-col min-h-screen w-screen select-none"
    v-bind:class="{
      'pb-25': isApp && $route.name != 'map',
    }"
    style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); -webkit-overflow-scrolling: touch"
  >
    <div
      class="w-full fixed z-50"
      v-bind:style="{
        height: 'calc(4.375rem + constant(safe-area-inset-top ) )',
        height: 'calc(4.375rem + env(safe-area-inset-top ) )',
      }"
      v-show="showHeader"
    >
      <search-dropdown />
      <app-header v-show="showHeader" />
    </div>

    <main>
      <nuxt keep-alive :keep-alive-props="{ include: 'home-page,map-page' }" />
    </main>

    <floating-menu />
    <app-footer v-show="showFooter" />
    <notification />
    <contribution-modal />
    <login />
    <categories-modal />
  </div>
</template>

<script>
import { EventBus } from '@/event-bus.js'
import AppHeader from '@/components/global/Header/AppHeader.vue'
import AppFooter from '@/components/global/Footer/AppFooter.vue'
import SearchDropdown from '@/components/global/Header/SearchDropdown.vue'
import FloatingMenu from '@/components/global/Mobile/FloatingMenu.vue'
import Notification from '@/components/global/Notification.vue'
import ContributionModal from '@/components/contributions/ContributionModal.vue'
import CategoriesModal from '@/components/search/CategoriesModal.vue'
import Login from '@/components/auth/Login.vue'

export default {
  data() {
    return {
      transitionName: '',
    }
  },

  components: {
    AppHeader,
    AppFooter,
    SearchDropdown,
    FloatingMenu,
    Notification,
    ContributionModal,
    CategoriesModal,
    Login,
  },

  computed: {
    isApp() {
      return process.env.mobile
    },

    showHeader() {
      let pages = ['contact', 'venue-new', 'venue-new-step-2', 'venue-id-country-name-edit', 'venue-id-country-name-upload']

      return pages.indexOf(this.$route.name) == -1
    },

    showFooter() {
      let pages = ['contact', 'venue-new', 'venue-new-step-2', 'venue-id-country-name-edit', 'venue-id-country-name-upload', 'map']

      return process.env.mobile ? false : pages.indexOf(this.$route.name) == -1
    },
  },

  beforeRouteEnter(to, from, next) {
    if (to.query.login == 'true') {
      EventBus.$emit('hide-register')
      EventBus.$emit('prompt-login')
    }

    if (to.query.register == 'true') {
      EventBus.$emit('hide-login')
      EventBus.$emit('prompt-register')
    }
    next()
  },

  watch: {
    $route(to, from) {
      if (to.query.login == 'true') {
        EventBus.$emit('hide-register')
        EventBus.$emit('prompt-login')
      }

      if (to.query.register == 'true') {
        EventBus.$emit('hide-login')
        EventBus.$emit('prompt-register')
      }
    },
  },

  head({ $config: { BASE_URL, ENVIRONMENT_TXT, ENVIRONMENT, API_PUBLIC_BASE_URL } }) {
    return {
      htmlAttrs: {
        lang: 'en',
        class: 'disable-scrollbars',
      },
      titleTemplate: '%s | Headbangers Travel Guide' + ENVIRONMENT_TXT,
      meta: [
        { charset: 'utf-8' },
        { hid: 'viewport', name: 'viewport', content: 'initial-scale=1, width=device-width, height=device-height, viewport-fit=cover', },
        { hid: 'description', name: 'description', content: 'Headbangers Travel Guide: A worldwide travel guide to find rock and metal clubs, bars, pubs and more.', },
        {
          hid: 'keywords', name: 'keywords', content: 'headbangerstravelguide, metaltravelguide, metal travel, metal guide, heavy metal travel guide, metaltravel, heavy metal, death metal, thrash metal, black metal, rock, heavy rock, grindcore, sludge metaltravel, guide, headbanger, headbanging, bar, club, concert, live event, metal pub, metal pubs, nu metal, heavy metal pubs, death metal pubs, thrash metal pubs, black metal pubs, rock pubs, heavy rock pubs, grindcore pubs, sludge metal pubsmetal clubs, heavy metal clubs, death metal clubs, thrash metal clubs, black metal clubs, rock clubs, heavy rock clubs, grindcore clubs, sludge metal clubslive fest, live festival, metal festival, metal festfestival, metal concert',
        },
        { hid: 'robots', name: 'robots', content: 'index, follow' },
        //{ hid: 'robots', name: 'robots', content: ENVIRONMENT == 'PROD' ? 'index, follow' : 'noindex, nofollow' },

        { hid: 'og:url', property: 'og:url', content: BASE_URL + this.$route.path },
        { hid: 'og:type', property: 'og:type', content: 'website' },
        //{ hid: 'og:title', property: 'og:title', content: this.title + ' | Headbangers Travel Guide' + ENVIRONMENT_TXT },
        { hid: 'og:description', property: 'og:description', content: 'Headbangers Travel Guide: A worldwide travel guide to find rock and metal clubs, bars, pubs and more.', },
        { hid: 'og:image', property: 'og:image', content: BASE_URL + '/img/seo/og.png', },
        { hid: 'og:site_name', property: 'og:site_name', content: 'Headbangers Travel Guide', },

        { hid: 'twitter:card', name: 'twitter:card', content: 'summary_large_image', },
        { hid: 'twitter:site', name: 'twitter:site', content: '@HeadbangersTG', },
        //{ hid: 'twitter:title', name: 'twitter:title', content: this.title + ' | Headbangers Travel Guide' + ENVIRONMENT_TXT },
        { hid: 'twitter:description', name: 'twitter:description', content: 'Headbangers Travel Guide: A worldwide travel guide to find rock and metal clubs, bars, pubs and more.', },
        { hid: 'twitter:image', name: 'twitter:image', content: BASE_URL + '/img/seo/og.png', },
      ],
      link: [
        { rel: 'preconnect', href: API_PUBLIC_BASE_URL },
        { rel: 'preconnect', href: 'https://maps.googleapis.com' },
        { rel: 'preconnect', href: 'https://cdn.jsdelivr.net' },
        { rel: 'dns-prefetch', href: API_PUBLIC_BASE_URL },
        { rel: 'dns-prefetch', href: 'https://maps.googleapis.com' },
        { rel: 'dns-prefetch', href: 'https://cdn.jsdelivr.net' },
        {
          rel: 'icon',
          type: 'image/x-icon',
          href: '/favicon-32x32.ico',
          sizes: '32x32',
        },
        {
          rel: 'icon',
          type: 'image/x-icon',
          href: '/favicon.ico',
          sizes: '16x16',
        },
        {
          rel: 'icon',
          type: 'image/svg+xml',
          href: '/favicon.svg',
          sizes: 'any',
        },
        {
          rel: 'canonical',
          href: BASE_URL + this.$route.path,
        },
      ],
    }
  },

  mounted() {
    if (this.$route.query.login == 'true') {
      EventBus.$emit('hide-register')
      EventBus.$emit('prompt-login')
    }

    if (this.$route.query.register == 'true') {
      EventBus.$emit('hide-login')
      EventBus.$emit('prompt-register')
    }
  },

  methods: {},
}
</script>
