export const state = () => ({
  categories: [],
  stats: {},
})

export const actions = {
  // nuxtServerInit is called by Nuxt.js before server-rendering every page
  async nuxtServerInit({ commit, dispatch }) {
    await dispatch('storeDispatchFunc')
  },

  // axios...
  async storeDispatchFunc({ commit }) {
    const [categories, stats] = await Promise.all([this.$api.metadata.categories(), this.$api.metadata.stats()])

    commit('SET_CATEGORIES', categories)
    commit('SET_STATS', stats)
  },
}

export const mutations = {
  SET_CATEGORIES(state, value) {
    state.categories = value
  },

  SET_STATS(state, value) {
    state.stats = value
  },
}
