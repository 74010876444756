<template>
  <div
    class="fixed flex items-center justify-center z-modal left-0 right-0 top-0 bottom-0 bg-secondaryBlackTransparent"
    v-show="show"
    v-bind:style="{
      height: height,
      paddingTop: paddingTop,
    }"
    @click="close()"
  >
    <transition :name="'slide-in-bottom'">
      <div
        class="rounded bg-white w-11/12"
        v-show="show"
        @click.stop
        v-bind:class="{
          'max-h-90': isApp,
          'overflow-y-scroll': isApp,
          'md:w-4/5 max-w-lg': !fullWidth,
        }"
      >
        <div v-show="header" class="flex bg-white z-10 justify-between items-center font-sans-lato font-bold text-2xl p-3 border-b border-grayBorder">
          <slot name="header"></slot>
          <span v-on:click="close()">
            <img src="~/assets/img/ui/close.svg" class="cursor-pointer" alt="Close" />
          </span>
        </div>
        <slot name="body"> </slot>
        <div v-show="footer" class="flex justify-end border-t p-3 border-grayBorder">
          <slot name="footer"></slot>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { EventBus } from '@/event-bus.js'

export default {
  props: ['show', 'header', 'footer', 'fullWidth'],

  data() {
    return {
      scrollPosition: 0,
      height: '100vh',
      paddingTop: '0px',
    }
  },

  computed: {
    isApp() {
      return process.env.mobile
    },
  },

  mounted() {
    document.addEventListener('keydown', (e) => {
      if (this.show && e.keyCode == 27) {
        this.close()
      }
    })    
  },

  watch: {
    show() {
      
    },
  },

  methods: {
    close() {
      EventBus.$emit('close-modal')
    },
  },
}
</script>