var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"slide-in-bottom"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],staticClass:"fixed z-100 bottom-0 left-0 right-0 bg-white border-t border-grayBorder py-1 px-2 md:px-7.5 lg:hidden",staticStyle:{"height":"calc(4.375rem + env(safe-area-inset-bottom) ;)","padding-bottom":"15px"},attrs:{"id":"htg-floating-menu"}},[_c('div',{staticClass:"flex justify-between items-center mx-auto max-w-lg h-17.5"},[_c('nuxt-link',{staticClass:"flex flex-col items-center justify-center w-16",attrs:{"to":{ path: '/', query: _vm.isApp ? { 'nav-bar': true } : '' }}},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.name != 'search' && _vm.$route.name != 'index'),expression:"$route.name != 'search' && $route.name != 'index'"}],attrs:{"src":require("assets/img/ui/discover.svg"),"alt":"Discover"}}),_vm._v(" "),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.name == 'search' || _vm.$route.name == 'index'),expression:"$route.name == 'search' || $route.name == 'index'"}],attrs:{"src":require("assets/img/ui/discover-active.svg"),"alt":"Discover"}}),_vm._v(" "),_c('span',{staticClass:"font-sans-lato font-bold text-tiny",class:{
            'text-brown': _vm.$route.name != 'search' && _vm.$route.name != 'index',
            'text-primaryGreen': _vm.$route.name == 'search' || _vm.$route.name == 'index',
          }},[_vm._v("Discover")])]),_vm._v(" "),_c('nuxt-link',{staticClass:"flex flex-col items-center justify-center w-16",attrs:{"to":{ path: '/map', query: _vm.isApp ? { 'nav-bar': true } : '' }}},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.name != 'map'),expression:"$route.name != 'map'"}],attrs:{"src":require("assets/img/ui/map.svg"),"alt":"Map"}}),_vm._v(" "),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.name == 'map'),expression:"$route.name == 'map'"}],attrs:{"src":require("assets/img/ui/map-active.svg"),"alt":"Map"}}),_vm._v(" "),_c('span',{staticClass:"font-sans-lato font-bold text-tiny",class:{
            'text-brown': _vm.$route.name != 'map',
            'text-primaryGreen': _vm.$route.name == 'map',
          }},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showNearMe),expression:"!showNearMe"}]},[_vm._v("Map")]),_vm._v(" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.showNearMe),expression:"showNearMe"}]},[_vm._v("Near Me")])])]),_vm._v(" "),(_vm.$auth.loggedIn)?_c('nuxt-link',{staticClass:"bg-primaryGreen rounded-full w-15 h-15 flex items-center justify-center",attrs:{"to":'/venue/new'}},[_c('img',{attrs:{"src":require("assets/img/ui/add.svg"),"alt":"Add Venue"}})]):_vm._e(),_vm._v(" "),(!_vm.$auth.loggedIn)?_c('a',{staticClass:"bg-primaryGreen rounded-full w-15 h-15 flex items-center justify-center cursor-pointer",on:{"click":function($event){return _vm.login('navigate-add-company')}}},[_c('img',{attrs:{"src":require("assets/img/ui/add.svg"),"alt":"Add Venue"}})]):_vm._e(),_vm._v(" "),(_vm.$auth.loggedIn)?_c('nuxt-link',{staticClass:"flex flex-col items-center justify-center w-16",attrs:{"to":{ path: '/account/likes', query: _vm.isApp ? { 'nav-bar': true } : '' }}},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.name != 'account-likes'),expression:"$route.name != 'account-likes'"}],attrs:{"src":require("assets/img/ui/likes.svg"),"alt":"Likes"}}),_vm._v(" "),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.name == 'account-likes'),expression:"$route.name == 'account-likes'"}],attrs:{"src":require("assets/img/ui/likes-active.svg"),"alt":"Likes"}}),_vm._v(" "),_c('span',{staticClass:"font-sans-lato font-bold text-tiny",class:{
            'text-brown': _vm.$route.name != 'account-likes',
            'text-primaryGreen': _vm.$route.name == 'account-likes',
          }},[_vm._v("Likes")])]):_vm._e(),_vm._v(" "),(!_vm.$auth.loggedIn)?_c('a',{staticClass:"flex flex-col items-center justify-center w-16 cursor-pointer",on:{"click":function($event){return _vm.login('navigate-likes')}}},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.name != 'account-likes'),expression:"$route.name != 'account-likes'"}],attrs:{"src":require("assets/img/ui/likes.svg"),"alt":"Likes"}}),_vm._v(" "),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.name == 'account-likes'),expression:"$route.name == 'account-likes'"}],attrs:{"src":require("assets/img/ui/likes-active.svg"),"alt":"Likes"}}),_vm._v(" "),_c('span',{staticClass:"font-sans-lato font-bold text-tiny",class:{
            'text-brown': _vm.$route.name != 'account-likes',
            'text-primaryGreen': _vm.$route.name == 'account-likes',
          }},[_vm._v("Likes")])]):_vm._e(),_vm._v(" "),(_vm.$auth.loggedIn)?_c('nuxt-link',{staticClass:"flex flex-col items-center justify-center w-16",attrs:{"to":{ path: '/account', query: _vm.isApp ? { 'nav-bar': true } : '' }}},[_c('GravatarImg',{attrs:{"email":_vm.$auth.user.email,"classes":"rounded-full w-5.5 h-5.5","alt":"Profile picture"}}),_vm._v(" "),(_vm.$auth.loggedIn)?_c('span',{staticClass:"font-sans-lato font-bold text-tiny text-brown truncate w-16 text-center"},[_vm._v(_vm._s(_vm.$auth.user.name))]):_vm._e()],1):_vm._e(),_vm._v(" "),(!_vm.$auth.loggedIn)?_c('a',{staticClass:"flex flex-col items-center justify-center w-16 cursor-pointer",on:{"click":function($event){return _vm.login()}}},[_c('img',{staticClass:"w-5 h-5",attrs:{"src":require("assets/img/ui/floating-nav-user.svg"),"alt":"Sign In"}}),_vm._v(" "),_c('span',{staticClass:"font-sans-lato font-bold text-tiny text-brown"},[_vm._v("Sign In")])]):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }