import Vue from 'vue'
import VueGtag from 'vue-gtag'

export default function ({ $config: { ENVIRONMENT } }, inject) {
  if (ENVIRONMENT == 'PROD') {
    Vue.use(VueGtag, {
      //TODO: Move to settings
      config: { id: 'G-11KTGG57MZ' }
    })
  }
}