import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _cbd4fa14 = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _442485af = () => interopDefault(import('../pages/callback.vue' /* webpackChunkName: "pages/callback" */))
const _e64111b4 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _40de8af0 = () => interopDefault(import('../pages/list.vue' /* webpackChunkName: "pages/list" */))
const _0d246162 = () => interopDefault(import('../pages/map.vue' /* webpackChunkName: "pages/map" */))
const _37e8fae4 = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _35b0bc07 = () => interopDefault(import('../pages/account/contributions.vue' /* webpackChunkName: "pages/account/contributions" */))
const _7cfbce40 = () => interopDefault(import('../pages/account/likes.vue' /* webpackChunkName: "pages/account/likes" */))
const _6438ad4d = () => interopDefault(import('../pages/account/profile.vue' /* webpackChunkName: "pages/account/profile" */))
const _285d5976 = () => interopDefault(import('../pages/diagnostic/health-check.vue' /* webpackChunkName: "pages/diagnostic/health-check" */))
const _80611c00 = () => interopDefault(import('../pages/diagnostic/test.vue' /* webpackChunkName: "pages/diagnostic/test" */))
const _f4aebcf4 = () => interopDefault(import('../pages/venue/new.vue' /* webpackChunkName: "pages/venue/new" */))
const _8e78de70 = () => interopDefault(import('../pages/venue/new-step-2.vue' /* webpackChunkName: "pages/venue/new-step-2" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _2896a874 = () => interopDefault(import('../pages/venue/_id/_country/_name/index.vue' /* webpackChunkName: "pages/venue/_id/_country/_name/index" */))
const _0c32136f = () => interopDefault(import('../pages/venue/_id/_country/_city/_name/index.vue' /* webpackChunkName: "pages/venue/_id/_country/_city/_name/index" */))
const _2a5acdd9 = () => interopDefault(import('../pages/venue/_id/_country/_city/_name/claim.vue' /* webpackChunkName: "pages/venue/_id/_country/_city/_name/claim" */))
const _2fd265fd = () => interopDefault(import('../pages/venue/_id/_country/_city/_name/edit.vue' /* webpackChunkName: "pages/venue/_id/_country/_city/_name/edit" */))
const _41ab07b4 = () => interopDefault(import('../pages/venue/_id/_country/_city/_name/upload.vue' /* webpackChunkName: "pages/venue/_id/_country/_city/_name/upload" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _cbd4fa14,
    name: "account"
  }, {
    path: "/callback",
    component: _442485af,
    name: "callback"
  }, {
    path: "/contact",
    component: _e64111b4,
    name: "contact"
  }, {
    path: "/list",
    component: _40de8af0,
    name: "list"
  }, {
    path: "/map",
    component: _0d246162,
    name: "map"
  }, {
    path: "/privacy",
    component: _37e8fae4,
    name: "privacy"
  }, {
    path: "/account/contributions",
    component: _35b0bc07,
    name: "account-contributions"
  }, {
    path: "/account/likes",
    component: _7cfbce40,
    name: "account-likes"
  }, {
    path: "/account/profile",
    component: _6438ad4d,
    name: "account-profile"
  }, {
    path: "/diagnostic/health-check",
    component: _285d5976,
    name: "diagnostic-health-check"
  }, {
    path: "/diagnostic/test",
    component: _80611c00,
    name: "diagnostic-test"
  }, {
    path: "/venue/new",
    component: _f4aebcf4,
    name: "venue-new"
  }, {
    path: "/venue/new-step-2",
    component: _8e78de70,
    name: "venue-new-step-2"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }, {
    path: "/venue/:id?/:country?/:name",
    component: _2896a874,
    name: "venue-id-country-name"
  }, {
    path: "/venue/:id?/:country?/:city?/:name",
    component: _0c32136f,
    name: "venue-id-country-city-name"
  }, {
    path: "/venue/:id?/:country?/:city?/:name?/claim",
    component: _2a5acdd9,
    name: "venue-id-country-city-name-claim"
  }, {
    path: "/venue/:id?/:country?/:city?/:name?/edit",
    component: _2fd265fd,
    name: "venue-id-country-city-name-edit"
  }, {
    path: "/venue/:id?/:country?/:city?/:name?/upload",
    component: _41ab07b4,
    name: "venue-id-country-city-name-upload"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
