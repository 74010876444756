<template>
  <div class="fixed top-0 right-0 left-0 bottom-0 bg-secondaryBlackTransparent z-modal" v-show="show" v-on:click="show = false">
    <transition name="slide-in-top">
      <div class="bg-white" v-show="show" v-on:click.stop>
        <form action="#" v-on:submit.prevent="search()" class="h-screen flex flex-col py-10 w-full mx-auto max-w-screen-xl px-4 md:px-5 lg:px-20 md:h-auto">
          <div class="flex justify-between">
            <h2 class="text-secondaryBlack text-3xl font-sans-lato font-bold mb-5">Search</h2>

            <span v-on:click="show = false" class="text-textGray text-3xl cursor-pointer w-10 h-10 flex items-center justify-center rounded-full hover:bg-autocompleteHoverGray">&times;</span>
          </div>

          <div class="flex flex-col w-full md:items-center md:justify-between md:flex-row">
            <div class="w-full pr-2 flex flex-col mt-5 md:w-3/4">
              <label class="font-sans-lato font-bold uppercase text-base">Venue/Name</label>
              <span class="font-sans-lato text-textGray text-sm">Enter a few keywords for the venue you are trying to find.</span>
              <input
                type="search"
                autocomplete="false"
                spellcheck="false"
                v-model="freeText"
                v-on:keydown.enter="search()"
                class="outline-0 placeholder-gray-600 font-sans-lato text-base text-secondaryBlack bg-white border rounded py-2 pr-4 pl-4 block w-full appearance-none leading-normal"
                id="htg-freetext-search"
              />
            </div>

            <div class="w-full flex items-start md:w-1/4">
              <a v-on:click="search()" class="rounded bg-primaryGreen font-sans-montserrat py-2 px-8 text-white text-base uppercase cursor-pointer w-full mt-5 text-center md:w-auto md:mt-16"
                >Search</a
              >
            </div>
          </div>
        </form>
      </div>
    </transition>
  </div>
</template>

<script>
import { EventBus } from '@/event-bus.js'

export default {
  data() {
    return {
      show: false,
    }
  },

  mounted() {
    EventBus.$on(
      'show-search',
      function () {
        this.show = true
      }.bind(this)
    )

    EventBus.$on(
      'htg-clear-local-filters',
      function () {
        this.freeText = ''
      }.bind(this)
    )
  },

  watch: {
    show() {
      if (this.show) {
        setTimeout(function () {
          document.getElementById('htg-freetext-search').focus()
        }, 100)
      }
    },
  },

  computed: {
    freeText: {
      get() {
        return this.$store.state.filters.freeText
      },

      set(value) {
        this.$store.commit('filters/setFreeText', value)
      },
    },
  },

  methods: {
    search() {
      this.show = false

      document.activeElement.blur()

      this.$router.push({
        path: '/map'
      })

      if (this.$route.name == 'map') {
        EventBus.$emit('htg-submit-search')
      }
    },
  },
}
</script>