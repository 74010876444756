<style scoped>
div#notification {
  top: env(safe-area-inset-top);
}
</style>
<template>
  <transition name="slide-in-top">
    <div id="notification" class="fixed left-0 right-0 z-modal" v-show="show">
      <div
        class="flex mx-auto max-w-lg rounded-b-5 border py-2 px-8"
        v-bind:class="{
          'bg-success': type == 'success',
          'bg-alertRed': type == 'error',
        }"
      >
        <div>
          <img src="~/assets/img/ui/success.svg" class="pointer-events-none" v-show="type == 'success'" alt="Success" />
          <svg class="h-5 w-5 text-white" v-show="type == 'error'" fill="currentColor" viewBox="0 0 20 20">
            <path
              d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
              clip-rule="evenodd"
              fill-rule="evenodd"
            ></path>
          </svg>
        </div>
        <div class="ml-3">
          <p class="text-base leading-5 font-sans-lato text-white">
            {{ message }}
          </p>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { EventBus } from '@/event-bus.js'

export default {
  data() {
    return {
      show: false,
      message: '',
      type: 'success',
    }
  },

  mounted() {
    EventBus.$on(
      'notify',
      function (data) {
        this.show = true
        this.message = data.message
        this.type = data.type

        setTimeout(
          function () {
            this.show = false
          }.bind(this),
          3000
        )
      }.bind(this)
    )
  },
}
</script>