export default ($axios) => ({
  async search(params) {
    return $axios.$get('/api/v1/venues/search', {
      params: params,
    })
  },

  async get(params) {
    return $axios.$get('/api/v1/venues', {
      params: params,
    })
  },

  async getAll() {
    return $axios.$get('/api/v1/venues-all')
  },

  async show(id) {
    return $axios.$get('/api/v1/venues/' + id)
  },

  async images(id) {
    return $axios.$get('/api/v1/venues/' + id + '/images')
  },

  async nearby(id) {
    return $axios.$get('/api/v1/venues/' + id + '/nearby')
  },

  async likes(id) {
    return $axios.$get('/api/v1/likes/' + id)
  },

  async like(id, like) {
    return $axios.$put('/api/v1/likes', { VenueId: id, Like: like })
  },

  async latest() {
    return $axios.$get('/api/v1/venues/latest-venues')
  },
})
