export default ($axios) => ({
  async lookup(placeId) {
    return $axios.$get('/api/v1/venueContributions/lookup/?placeId=' + placeId)
  },

  async get(id) {
    return $axios.$get('/api/v1/venueContributions/' + id)
  },

  async create(venue) {
    return $axios.$post('/api/v1/venueContributions', venue)
  },

  async update(id, venue) {
    return $axios.$post('/api/v1/venueContributions/' + id, venue)
  },

  async delete(id, venue) {
    return $axios.$delete('/api/v1/venueContributions/' + id, {
      headers: {
        'Content-Type': 'application/json',
      },
      data: venue,
    })
  },
})
