import { Loader } from '@googlemaps/js-api-loader'

export default function ({ $config: { GOOGLEMAPS_KEY } }, inject) {
  const loader = new Loader({
    apiKey: GOOGLEMAPS_KEY,
    version: 'weekly',
    libraries: ['places'],
  })

  inject('GoogleMapsLoader', loader)
}
