var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],staticClass:"fixed flex items-center justify-center z-modal left-0 right-0 top-0 bottom-0 bg-secondaryBlackTransparent",style:({
    height: _vm.height,
    paddingTop: _vm.paddingTop,
  }),on:{"click":function($event){return _vm.close()}}},[_c('transition',{attrs:{"name":'slide-in-bottom'}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],staticClass:"rounded bg-white w-11/12",class:{
        'max-h-90': _vm.isApp,
        'overflow-y-scroll': _vm.isApp,
        'md:w-4/5 max-w-lg': !_vm.fullWidth,
      },on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.header),expression:"header"}],staticClass:"flex bg-white z-10 justify-between items-center font-sans-lato font-bold text-2xl p-3 border-b border-grayBorder"},[_vm._t("header"),_vm._v(" "),_c('span',{on:{"click":function($event){return _vm.close()}}},[_c('img',{staticClass:"cursor-pointer",attrs:{"src":require("assets/img/ui/close.svg"),"alt":"Close"}})])],2),_vm._v(" "),_vm._t("body"),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.footer),expression:"footer"}],staticClass:"flex justify-end border-t p-3 border-grayBorder"},[_vm._t("footer")],2)],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }