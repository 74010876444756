import VenuesAPI from '@/api/venues.js'
import ClaimsAPI from '@/api/claims.js'
import UserAPI from '@/api/user.js'
import ContributionsAPI from '@/api/contributions.js'
import MetadataAPI from '@/api/metadata.js'
import VenueContributionsAPI from '@/api/venueContributions.js'
import ContactAPI from '@/api/contact.js'

export default function (
  { $axios, $appInsights, $config: { ENVIRONMENT } },
  inject
) {
  $axios.onError((error) => {
    const e = new Error(error)
    e.stack = error.stack
    if (ENVIRONMENT === 'DEV' || ENVIRONMENT === 'STAGING') {
      console.error(e.stack)
    }
    $appInsights.trackException({ exception: e })
  })

  const api = {
    venues: VenuesAPI($axios),
    claims: ClaimsAPI($axios),
    user: UserAPI($axios),
    contributions: ContributionsAPI($axios),
    metadata: MetadataAPI($axios),
    venueContributions: VenueContributionsAPI($axios),
    contact: ContactAPI($axios),
  }

  inject('api', api)
}
