const middleware = {}

middleware['admin'] = require('../middleware/admin.js')
middleware['admin'] = middleware['admin'].default || middleware['admin']

middleware['guest'] = require('../middleware/guest.js')
middleware['guest'] = middleware['guest'].default || middleware['guest']

middleware['owner'] = require('../middleware/owner.js')
middleware['owner'] = middleware['owner'].default || middleware['owner']

middleware['verified'] = require('../middleware/verified.js')
middleware['verified'] = middleware['verified'].default || middleware['verified']

export default middleware
