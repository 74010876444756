<template>
  <modal :show="show" :header="true" :footer="false" :fullWidth="false">
    <template v-slot:header> Your contribution has been submitted! </template>

    <template v-slot:body>
      <div class="flex flex-col items-center p-5">
        <img src="~/assets/img/ui/high-five.svg" loading="lazy" class="pointer-events-none py-4" alt="High five" />
        <h2 class="text-center font-sans-lato font-bold text-base">One of our admins will review your submission soon.</h2>
        <p class="text-center mt-1 font-sans-lato text-base text-secondaryBlack">
          You can always check the status of your contribution on the <nuxt-link :to="'/account/contributions'" class="text-linkBlue">My Contributions</nuxt-link> page.
        </p>
        <a v-on:click="hide()" class="cursor-pointer text-center rounded bg-primaryGreen font-sans-montserrat py-3 px-8 text-white text-base uppercase mt-4">Close Window</a>
      </div>
    </template>
  </modal>
</template>

<script>
import Modal from '@/components/global/Modal.vue'
import { EventBus } from '@/event-bus.js'

export default {
  data() {
    return {
      show: false,
    }
  },

  components: {
    Modal,
  },

  mounted() {
    EventBus.$off('close-modal')
    EventBus.$on(
      'close-modal',
      function () {
        this.show = false
      }.bind(this)
    )

    EventBus.$off('show-contribution')
    EventBus.$on(
      'show-contribution',
      function () {
        this.show = true
      }.bind(this)
    )
  },

  methods: {
    hide() {
      this.show = false
    },
  },
}
</script>