<template>
  <transition name="slide-in-bottom">
    <div
      id="htg-floating-menu"
      v-show="show"
      class="fixed z-100 bottom-0 left-0 right-0 bg-white border-t border-grayBorder py-1 px-2 md:px-7.5 lg:hidden"
      style="height: calc(4.375rem + constant(safe-area-inset-bottom) ;); height: calc(4.375rem + env(safe-area-inset-bottom) ;); padding-bottom: 15px"
    >
      <div class="flex justify-between items-center mx-auto max-w-lg h-17.5">
        <nuxt-link :to="{ path: '/', query: isApp ? { 'nav-bar': true } : '' }" class="flex flex-col items-center justify-center w-16">
          <img src="~/assets/img/ui/discover.svg" alt="Discover" v-show="$route.name != 'search' && $route.name != 'index'" />
          <img src="~/assets/img/ui/discover-active.svg" alt="Discover" v-show="$route.name == 'search' || $route.name == 'index'" />
          <span
            class="font-sans-lato font-bold text-tiny"
            v-bind:class="{
              'text-brown': $route.name != 'search' && $route.name != 'index',
              'text-primaryGreen': $route.name == 'search' || $route.name == 'index',
            }"
            >Discover</span
          >
        </nuxt-link>

        <nuxt-link :to="{ path: '/map', query: isApp ? { 'nav-bar': true } : '' }" class="flex flex-col items-center justify-center w-16">
          <img src="~/assets/img/ui/map.svg" alt="Map" v-show="$route.name != 'map'" />
          <img src="~/assets/img/ui/map-active.svg" alt="Map" v-show="$route.name == 'map'" />
          <span
            class="font-sans-lato font-bold text-tiny"
            v-bind:class="{
              'text-brown': $route.name != 'map',
              'text-primaryGreen': $route.name == 'map',
            }"
          >
            <span v-show="!showNearMe">Map</span>
            <span v-show="showNearMe">Near Me</span>
          </span>
        </nuxt-link>

        <nuxt-link v-if="$auth.loggedIn" :to="'/venue/new'" class="bg-primaryGreen rounded-full w-15 h-15 flex items-center justify-center">
          <img src="~/assets/img/ui/add.svg" alt="Add Venue" />
        </nuxt-link>

        <a v-if="!$auth.loggedIn" v-on:click="login('navigate-add-company')" class="bg-primaryGreen rounded-full w-15 h-15 flex items-center justify-center cursor-pointer">
          <img src="~/assets/img/ui/add.svg" alt="Add Venue" />
        </a>

        <nuxt-link v-if="$auth.loggedIn" :to="{ path: '/account/likes', query: isApp ? { 'nav-bar': true } : '' }" class="flex flex-col items-center justify-center w-16">
          <img src="~/assets/img/ui/likes.svg" alt="Likes" v-show="$route.name != 'account-likes'" />
          <img src="~/assets/img/ui/likes-active.svg" alt="Likes" v-show="$route.name == 'account-likes'" />
          <span
            class="font-sans-lato font-bold text-tiny"
            v-bind:class="{
              'text-brown': $route.name != 'account-likes',
              'text-primaryGreen': $route.name == 'account-likes',
            }"
            >Likes</span
          >
        </nuxt-link>

        <a v-if="!$auth.loggedIn" v-on:click="login('navigate-likes')" class="flex flex-col items-center justify-center w-16 cursor-pointer">
          <img src="~/assets/img/ui/likes.svg" alt="Likes" v-show="$route.name != 'account-likes'" />
          <img src="~/assets/img/ui/likes-active.svg" alt="Likes" v-show="$route.name == 'account-likes'" />
          <span
            class="font-sans-lato font-bold text-tiny"
            v-bind:class="{
              'text-brown': $route.name != 'account-likes',
              'text-primaryGreen': $route.name == 'account-likes',
            }"
            >Likes</span
          >
        </a>

        <nuxt-link v-if="$auth.loggedIn" :to="{ path: '/account', query: isApp ? { 'nav-bar': true } : '' }" class="flex flex-col items-center justify-center w-16">
          <GravatarImg :email="$auth.user.email" classes="rounded-full w-5.5 h-5.5" alt="Profile picture" />
          <span v-if="$auth.loggedIn" class="font-sans-lato font-bold text-tiny text-brown truncate w-16 text-center">{{ $auth.user.name }}</span>
        </nuxt-link>

        <a v-if="!$auth.loggedIn" v-on:click="login()" class="flex flex-col items-center justify-center w-16 cursor-pointer">
          <img src="~/assets/img/ui/floating-nav-user.svg" class="w-5 h-5" alt="Sign In" />
          <span class="font-sans-lato font-bold text-tiny text-brown">Sign In</span>
        </a>
      </div>
    </div>
  </transition>
</template>

<script>
import { EventBus } from '@/event-bus.js'
import GravatarImg from '@/components/global/GravatarImg.vue'

export default {
  data() {
    return {
      show: true,
    }
  },

  components: {
    GravatarImg,
  },

  computed: {
    showNearMe() {
      return process.env.mobile
    },

    isApp() {
      return process.env.mobile
    },
  },

  mounted() {
    if (!this.isApp) {
      window.addEventListener(
        'scroll',
        function () {
          if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
            this.show = false
          } else {
            this.show = true
          }
        }.bind(this)
      )
    }
  },

  methods: {
    login(preAuthAction = '') {
      if (preAuthAction != '' && preAuthAction == 'navigate-likes') {
        this.$store.commit('pendingActions/setPreAuthAction', {
          action: 'navigate',
          route: '/account/likes',
        })
      }

      if (preAuthAction != '' && preAuthAction == 'navigate-add-company') {
        this.$store.commit('pendingActions/setPreAuthAction', {
          action: 'navigate',
          route: '/venue/new',
        })
      }

      if (preAuthAction == '') {
        this.$store.commit('pendingActions/setPreAuthAction', {})
      }

      EventBus.$emit('prompt-login')
    },
  },
}
</script>
