<template>
  <div class="flex items-center justify-center">
    <div class="flex flex-col my-15 items-center" v-if="error.statusCode == 404">
      <img src="~/assets/img/ui/404.svg" class="mb-5 pointer-events-none" alt="404" />
      <span class="text-secondaryBlack text-2xl font-bold font-sans-lato text-center md:text-3xl lg:text-5xl">404 - Page Not Found</span>
      <span class="text-textGray text-xl font-sans-lato text-center md:text-2xl lg:text-3xl">We know you’re looking for metal and beer, but unfotunately you will not find it here.</span>

      <nuxt-link :to="'/map'" class="rounded bg-primaryGreen font-sans-montserrat py-3 px-8 text-white text-base uppercase text-center mt-5">Find More Metal And Beer</nuxt-link>
    </div>
    <div class="flex flex-col my-15" v-if="error.statusCode != 404">
      <h1 class="font-sans-lato font-bold text-4xl text-center">An error occured</h1>
      <h2 class="font-sans-lato text-2xl text-center mt-2">Spilled your beer?</h2>

      <img src="~/assets/img/ui/coffee-to-go.svg" class="pointer-events-none" alt="" />
      <p class="font-sans-lato text-base text-center mb-5">Don't worry, we can help you find more!</p>

      <nuxt-link to="/" class="rounded bg-primaryGreen font-sans-montserrat py-3 px-8 text-white text-base uppercase text-center">Home</nuxt-link>
    </div>
  </div>
</template>

<script>
export default {
  props: ['error'],
  layout: 'App',
  head() {
    return {
      title: 'Error',
    }
  },
}
</script>
