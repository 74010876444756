export default ($axios) => ({
  async stats() {
    return $axios.$get('/api/v1/metadata/stats')
  },

  async categories() {
    return $axios.$get('/api/v1/metadata/categories')
  },

  async countries() {
    return $axios.$get('/api/v1/metadata/countries')
  },

  async healthCheck() {
    return $axios.$get('/diagnostic/health-check')
  },
})
